import fileDownload from 'js-file-download'
import { ThemeProvider } from '@emotion/react'
import { Box, Button, Container, IconButton, Tooltip } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import * as React from 'react'
import theme from '../../themeLayout'
import api from '../../services/api'
import AddIcon from '@mui/icons-material/Add';
import FormDialog from './FormDialog'
import { useAuth } from '../../auth/AuthProvider'
import { useNavigate } from 'react-router-dom'
import textLabels from '../../componets/textLabelsTable';
import moment from 'moment';
import 'moment/locale/pt-br'
import { Download,  Visibility } from '@mui/icons-material'



export default function(){
    const module = "/backup"
    const navigate = useNavigate()
    const [ handlerDialog, setHandlerDialog ] = React.useState(false)
    const [update, setUpdate] = React.useState(false)
    const [id, setId] = React.useState()
    const  auth = useAuth()
    React.useEffect(()=>{
        document.title = "Backups - Hermes Backup"
      },[])

      React.useEffect(()=>{    
        updateData()
    },[])

    const [data, setData] = React.useState({results:[], limit:10, total:0})
    const columns =[
        {
            name:'id',
            label: "ID",
            options: {
                filter: false,
                sort:false,
                display: false
            }
        },
        {
            name:'host_name',
            label: "Nome",
            options: {
                filter: true,
                sort:false,}
        },
        {
            name: "ip_address",
            label:'IP Address',
            options: {
                filter: true,
                sort:false,}
        },
        {
            name: "vendor",
            label:'Marca',
            options: {
                filter: true,
                sort:false,}
        },
        {
            name: "type",
            label:'Tipo',
            options: {
                filter: true,
                sort:false,}
        },
        {
          label: "Data de criação",
          name: "created_at",
          options:{
            filter: false,
            sort: false,
            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')} ({moment(value).locale('pt-br').fromNow()})</span>,
          }
        },        
        {
            name: "actions",
            label: "Ações",
            options:{
              filter: false,
              sort: false,
              customBodyRenderLite : (dataIndex, rowIndex)=>
              <>
                <Tooltip title="Visualizar">
                      <Button  sx={{ color:theme.palette.text.primary}}   onClick={()=>{
                        const id = data.results[rowIndex].id
                        setId(id)
                        setHandlerDialog(true)
                      }}>
                      <Visibility/>
                  </Button>
                  </Tooltip>
                  <Tooltip title="Baixar">
                      <Button sx={{ color:theme.palette.text.primary}}   onClick={()=>{
                        const id = data.results[rowIndex].id
                        api.get(`${module}/download/${id}`).then(res=>{
                          //Downlaod txt file
                        
                          fileDownload(res.data, `${data.results[rowIndex].host_name} - ${data.results[rowIndex].ip_address} - ${moment(data.results[rowIndex].created_at).format('DD-MM-YYYY HH:mm:ss')}.txt`);                        })
                      }}>
                      <Download/>
                  </Button>
                  </Tooltip>
             
            </>
            
            }
          }
    ]
    const deleteItem =(id, name)=>{
        const conf = confirm(`Deseja realmente apagar o Backup: ${name}`)
        if(!conf) return
        api.delete(`${module}/${id}/`).then(()=>{
           setUpdate(true)
        }).catch(err=>console.log(err))
    }
    const updateData = (page = 0, limit = 10, search=false) => {
        const skip = page * limit;
        const url = search ? `${module}/?skip=${skip}&limit=${limit}&search=${search}` : `${module}/?skip=${skip}&limit=${limit}`;
      
        api.get(url)
          .then(res => {
            setData(res.data);
          })
          .catch(err => {
            if (err.response && err.response.status === 401) {
              navigate('/401');
            }
            setData();
          });
      };
    const showFormDialog = ()=>{
        setId()
        setHandlerDialog(true)
    }






    const options = {
        filterType: 'checkbox',
        filter: false,
        rowsPerPageOptions : [ 10, 30,100, 500],
        rowsPerPage: 10,
        textLabels,
        rowHover: false,
        print: false,
        rowsPerPage: data?.limit,
        count: data?.total,
        selectableRows: 'none' ,
        download: false,
       
        customToolbarSelect: () => {},
        serverSide: true,
        storageKey: 'backups',
        onTableChange: (action, tableState) => {
            const {page,rowsPerPage, searchText } = tableState
            switch (action) {
              case 'changePage':
                updateData(page)
                break;
              case 'changeRowsPerPage':
                updateData(page, rowsPerPage)
                break;
              case 'search':
                updateData(page, rowsPerPage, searchText)
                break;
              case "propsUpdate":
                if (update){
                  setUpdate(false)
                  updateData(page, rowsPerPage, searchText)
                }
                break;
              default:
               //console.log(action, tableState);
            }
          },
    }
    document.get
    return(
        <Container  maxWidth='lg'>
            <ThemeProvider theme={theme}>
            <FormDialog open={handlerDialog} id={id} handlerClose={()=>{
              setHandlerDialog(false) 
              setUpdate(true)}} />
            <Box sm={12} lg={10}>
            <MUIDataTable
            title={"Backups"}
            data={data?.results}
            columns={columns}
            options={options}
            />
            </Box>
        </ThemeProvider>
          </Container>
    )
}