import { ThemeProvider } from '@emotion/react'
import { Box, Button, CircularProgress, Container, Grid, Icon, IconButton, Tooltip } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import * as React from 'react'
import theme from '../../themeLayout'
import api from '../../services/api'
import AddIcon from '@mui/icons-material/Add';
import FormDialog from './FormDialog'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../auth/AuthProvider'
import { useNavigate } from 'react-router-dom'
import textLabels from '../../componets/textLabelsTable';
import { Snack } from "./FormDialog"
import { ArrowBack, Download, FileUpload, PlayArrow } from '@mui/icons-material'
import FormDialogUpload from './FormDialogUpload'

export default function () {
  const module = "/host"
  const navigate = useNavigate()
  const [handlerDialog, setHandlerDialog] = React.useState(false)
  const [handlerDialogUpload, setHandlerDialogUpload] = React.useState(false)
  const [update, setUpdate] = React.useState(false)
  const [id, setId] = React.useState()
  const auth = useAuth()
  const [isRun, setIsRun] = React.useState([
  ])
  const [snack, setSnack] = React.useState({ open: false, message: '', severity: 'success' })

  const showNotify = (message, severity = 'success') =>  setSnack({ open: true, message, severity })

  React.useEffect(() => {
    document.title = "Hosts - Hermes Backup"
  }, [])

  React.useEffect(() => {
    // Chame a função updateData a cada 30 segundos (30000 milissegundos)
    const intervalId = setInterval(() => setUpdate(true), 30000);

    // Retorne uma função de limpeza para cancelar o setInterval quando o componente for desmontado
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  React.useEffect(() => {
    updateData()
  }, [])
  const [data, setData] = React.useState({ results: [], limit: 10, total: 0 })
  const columns = [
    {
      name: 'id',
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: 'host_name',
      label: "Nome",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "ip_address",
      label: 'IP Address',
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "vendor",
      label: 'Marca',
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "type",
      label: 'Tipo',
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "status",
      label: 'Status',
      options: {
        customBodyRender: value => <span>{value ? "Ativo" : 'Inativo'}</span>,
        filter: true,
        sort: false,
      }
    },

    {
      name: "actions",
      label: "Ações",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) =>
          <Grid container spacing={0} sx={{
            "display": "flex",
            "justifyContent": "flex-start",
            
          }}>

            <Grid item xs={12} sm={3}>
              <Button disabled={!auth.hasPerm('host.update')} sx={{ color: theme.palette.text.primary,  }} onClick={() => {
                const id = data.results[rowIndex].id

                setId(id)
                setHandlerDialog(true)
              }}>
                <EditIcon />
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
            

                <Button disabled={!auth.hasPerm('host.update') || isRun.find(e=> e==data.results[rowIndex].id)}  sx={{ color: theme.palette.text.primary,  }} onClick={() => {
                  const hostid = data.results[rowIndex].id
                  setIsRun(state=>[...state,hostid])
                  api.post(`backup/${hostid}/`).then(res => {
                      setIsRun(s=> s.filter(id=> id!=hostid))
                      
                      showNotify(`Backup do host ${data.results[rowIndex].host_name} foi finalizado!`)
                  }).catch(err => {
                      setIsRun(s=> s.filter(id=> id!=hostid))
                      showNotify(`Erro ao realizar backup do host ${data.results[rowIndex].host_name}`, 'error')
                  })
                 
                }}>
                    {(data?.results[rowIndex].queue_status || isRun.find(e=> e==data.results[rowIndex].id)) ?
                   <CircularProgress color="inherit" size="1.3rem" />:
                   <PlayArrow />
                  }
                </Button>

            </Grid>
          </Grid>

      }
    }
  ]
 
  const updateData = (page = 0, limit = 30, search = false) => {
    const skip = page * limit;

    const url = search ? `${module}/?skip=${skip}&limit=${limit}&search=${search}` : `${module}/?skip=${skip}&limit=${limit}`;

    api.get(url)
      .then(res => {
        setData(res.data);
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          navigate('/401');
        }
        setData();
      });
  };
  const showFormDialog = () => {
    setId()
    setHandlerDialog(true)
  }






  const options = {
    filterType: 'checkbox',
    rowsPerPageOptions: [10, 30, 100, 500],
    rowsPerPage: 10,
    textLabels,
    rowHover: false,
    print: false,
    filter: false,
    rowsPerPage: data?.limit,
    count: data?.total,
    selectableRows: 'none',
    download: false,
    customToolbar: () => <>
      <Tooltip title="Importar hosts por CSV">
        <IconButton  disabled={!auth.hasPerm('host.create')}  onClick={() => setHandlerDialogUpload(true)} ><FileUpload /></IconButton>
      </Tooltip>
      <Tooltip title="Criar novo host">
        <IconButton disabled={!auth.hasPerm('host.create')}  onClick={showFormDialog} ><AddIcon /></IconButton>
      </Tooltip>
      <Tooltip title="Executar todos os backups">
        <IconButton disabled={!auth.hasPerm('backup.create')} onClick={async () => {
          confirm('Deseja executar todos os backups?') ? api.post(`backup/all/`): null
          setTimeout(() => setUpdate(true), 1000)
          
        }}>
          <PlayArrow />
        </IconButton>
      </Tooltip>
    </>
    ,
    customToolbarSelect: () => { },
    serverSide: true,
    storageKey: 'hosts',
    onTableChange: (action, tableState) => {
      const { page, rowsPerPage, searchText } = tableState
      switch (action) {
        case 'changePage':
          updateData(page, rowsPerPage, searchText)
          break;
        case 'changeRowsPerPage':
          updateData(page, rowsPerPage, searchText)
          break;
        case 'search':
          updateData(page, rowsPerPage, searchText)
          break;
        case "propsUpdate":
          if (update) {
            setUpdate(false)
            updateData(page, rowsPerPage, searchText)
          }
          break;
        default:
        //console.log(action, tableState);
      }
    },
  }
  return (
  

    <Container maxWidth='lg' >
      <Snack open={snack.open} message={snack.message} severity={snack.severity} handlerSnack={() => setSnack({ ...snack, open: false })} />  
      <ThemeProvider theme={theme}>
        <FormDialog open={handlerDialog} id={id} handlerClose={() => {
          setHandlerDialog(false)
          setUpdate(true)
          }} />
        <FormDialogUpload open={handlerDialogUpload} id={id} handlerClose={() => {
          setHandlerDialogUpload(false)
          setUpdate(true)
        }} />
        <Box sm={12} lg={10}>
          <MUIDataTable
            title={"Hosts"}
            data={data?.results}
            columns={columns}
            options={options}
          />
        </Box>
      </ThemeProvider>
    </Container>
    
  )
}