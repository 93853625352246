import  { useEffect, useState } from 'react';
import api from '../../services/api';
import Card from '../../componets/card';


export default function () {
  const [data, setData] = useState(0)
  useEffect(() => {
    api.get("dashboard/all-backups").then((res) => {
      setData(res.data)
    }).catch((err) => {
      console.log(err)
    })
  },[])
    return (
    <Card title={data} subtitle="Backups realizados"/>
  );
}