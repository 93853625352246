import { grey, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';



const theme = createTheme({
  typography:{
      fontFamily:[
        'sans-serif',
        'Roboto',
        'Montserrat',
      ]
    },
    palette: {
      mode: 'light',
      primary: {
        main: "#e01b29",
      },
      text: {
        //primary: '#fdfdfd',
        //secondary: '#fdfdfd',
        disabled: '#dadada',
        hint: '#245fd6',
      },
      },
      background: {
        paper: '#fdfdfd',
      },

})

export default theme;
