import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import theme from '../../themeLayout'
import { Alert, Autocomplete, Box, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Snackbar } from '@mui/material';
import api from '../../services/api';
import { render } from 'react-dom';
import { ConstructionOutlined } from '@mui/icons-material';
import { useAuth } from '../../auth/AuthProvider';
import { ThemeProvider } from 'styled-components';
import { grey } from '@mui/material/colors';




export default function FormDialog(props) {
  const [handlerSnack, setHandlerSnack] = React.useState()

  const item = {
    min_size_backup: '',
    timeout: '',
    time_backup: "",

  }

  const [state, setState] = React.useState(item);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };


  React.useEffect(() => {
    if (!props.open) return
    getEditInfo()

  }, [ props.open])

  const getEditInfo = () => {
    setState(item)
    api.get(`config/`)
      .then(res => {
        setState(res.data)
      }).catch(err => setState(item))

  }
  const showNotify = (msg, sev = "success") => {
    setMsgSnack(msg)
    setSevSnack(sev)
    setHandlerSnack(true)
  }

  const [msgSnack, setMsgSnack] = React.useState('')
  const [sevSnack, setSevSnack] = React.useState('')
 

 

  const handleSubmit = (event) => {
    const handlerClose = () => {
      props.handlerClose()
    }
    event.preventDefault()
   
      api.put('config/', state)
        .then(() => {
          showNotify("Configurações alteradas com sucesso.")
          handlerClose()
        }).catch((err) => {
          console.l
          showNotify("Um erro ocorreu...", 'error')
        })
    
  }
  const auth = useAuth()
  return (
    <ThemeProvider theme={theme}>
      <Snack open={handlerSnack} message={msgSnack} severity={sevSnack} handlerSnack={() => setHandlerSnack(false)} />
      <Dialog open={props.open} fullWidth={true} scroll={'paper'}
      
        maxWidth='sm'>

        <Box component="form" onSubmit={handleSubmit} sx={{backgroundColor: grey[100]}}>
          <DialogTitle>
            Configurações
          </DialogTitle>
          <DialogContent>

            <TextField
              required
              disabled={!auth.hasPerm('config.update')}
              size="small"
              margin="dense"
              id="timeout"
              name="timeout"
              label="Tempo máximo de execução do backup(segundos)"
              type="number"
              fullWidth
              value={state.timeout}
              onChange={handleChange}
              variant="outlined"
            />

           
            <TextField
              disabled={!auth.hasPerm('config.update')}
              size="small"
              required
              margin="dense"
              id="time_backup"
              name="time_backup"
              label="Horário do backup"
              type="time"
              fullWidth
              step="3600"
              value={state.time_backup}
              onChange={handleChange}
              variant="outlined"
            />
            <TextField
              disabled={!auth.hasPerm('config.update')}
              size="small"
              required
              margin="dense"
              id="min_size_backup"
              name="min_size_backup"
              label="Tamanho mínimo do backup(bytes)"
              type="number"
              fullWidth
              value={state.min_size_backup}
              onChange={handleChange}
              variant="outlined"
              />


          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={props.handlerClose}>Fechar</Button>
            <Button variant='contained' color="primary" disabled={!auth.hasPerm('config.update')} type='submit'> Salvar</Button>
          </DialogActions>
        </Box>
      </Dialog>
    
  </ThemeProvider>
  );
}

const Snack = (props) => {
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={6000}
      onClose={() => { props.handlerSnack() }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Alert onClose={() => { props.handlerSnack() }} severity={props.severity ? props.severity : 'error'} sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </Snackbar>
  )
}