import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';

import { MainListItems, secondaryListItems } from './listItems';

import { grey } from '@mui/material/colors';
import logo from "../../assets/images/logo.png";

import { Logout } from '@mui/icons-material';
import { AppBar, Drawer, Tooltip } from '@mui/material';
import { Outlet } from 'react-router-dom';
import theme from '../../theme';
import power_ipv7 from "../../assets/images/powered.png";
import { useAuth } from '../../auth/AuthProvider';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import base from './base.css'
const drawerWidth = 240;


const DrawerCustom = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.


export default function Dashboard() {
  const [open, setOpen] = React.useState(false);
  const auth = useAuth();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar elevation={2} position='fixed' open={open}

          sx={{

            height: 64
          }}
        >
          <Toolbar disableGutters
            sx={{
              backgroundColor: {
                xs: grey[900],
                sm: grey[900],
                md: "#C4161C",
              },
              pr: '24px', // keep right padding when drawer closed
              height: '64px',
              color: grey[100],

            }}
          >
            <Box
              display={'flex'}
              justifyContent={"space-around"}
            >

            </Box>

            <Box

              justifyContent={"center"}
              alignItems={"center"}
              width={{ xs: "auto", sm: "auto", md: "240px" }}
              sx={{
                backgroundColor: grey[900],
                display: { md: "flex" },
                height: 64
              }}

            >
              <Toolbar
                sx={{
                  display: { xs: "flex", sm: "flex", md: "none" },
                }}
              >

                <Tooltip>
                  <IconButton onClick={toggleDrawer} sx={{

                    color: grey[100],

                  }}>
                    <MenuIcon />
                  </IconButton>
                </Tooltip>
              </Toolbar>
              <Box

                sx={{
                  display: { xs: "none", sm: "none", md: "flex" },
                }}>

                <img src={logo}

                  style={{
                    width: 100,




                  }}
                />
              </Box>
            </Box>


            <Typography
              component="h1"
              variant="h6"
              color="inherit"

              noWrap
              sx={{
                flexGrow: 1,

              }}
            ></Typography>
            <Box
              sx={{ display: { xs: "flex", sm: "flex", md: "none" }, }}
            >

              <img src={logo}

                style={{
                  width: 100,




                }}
              />
            </Box>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"

              noWrap
              sx={{
                flexGrow: 1,

              }}
            ></Typography>
            <Typography >
              {auth.name}
            </Typography>
            <Tooltip title="Sair">
              <IconButton onClick={() => {
                auth.signout(() => console.log("Saindo...."));
              }} color="inherit">
                <Logout />
              </IconButton>
            </Tooltip>
          </Toolbar>

          <DrawerCustom variant="permanent" open={true} //Drawer desktop
            sx={{
              width: drawerWidth,

              display: { xs: "none", sm: "none", md: "block" },
              height: "calc(100vh - 64px)"
            }}

          >
            <Divider />

            <MainListItems />
            <div style={{ marginTop: "auto" }} />
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
              height={"75px"}

            >
              <img src={power_ipv7} style={{
                width: "40%",

                //objectFit: "contain",
                objectPosition: "center bottom",


                alignSelf: 'center',
                justifyContent: 'center',
                justifySelf: 'flex-end',
              }} />
            </Box>
          </DrawerCustom>
          <Drawer variant="temporary" open={open} //drawer responsive
            onClose={toggleDrawer}
            sx={{
              width: drawerWidth,
              display: { xs: "block", sm: "block", md: "display" },
              height: "calc(100vh - 64px)"
            }}

          >
            <Divider />
            <MainListItems open={open} />
            <div style={{ marginTop: "auto" }} />
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              width={drawerWidth}
              height={"75px"}

            >
              <img src={power_ipv7} style={{
                width: "40%",

                //objectFit: "contain",
                objectPosition: "center bottom",


                alignSelf: 'center',
                justifyContent: 'center',
                justifySelf: 'flex-end',
              }} />
            </Box>
          </Drawer>
        </AppBar>
        <Box
          sx={{
            width: 240,
            display: { xs: "none", sm: "none", md: "block" },
          }}
        ></Box>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            minHeight: '100vh',

            width: { sm: "100%", md: `calc(100% - ${drawerWidth}px)` }
            //overflow: 'hidden'

          }}
        >
          <Toolbar />


          <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
            <Outlet />

          </Container>

        </Box>



      </Box>
    </ThemeProvider>
  );
}
