import * as React from "react";
import {
  Routes,
  Route,
  Link,
  Swit,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import  Dashboard from './layout/dashboard/Dashboard'
import LoginPage from './pages/login/LoginPage'
import { AuthProvider, RequireAuth} from './auth/AuthProvider'

import Main from "./pages/main"
import Hosts from "./pages/hosts";
import Backups from "./pages/backups";
import ErroBackups from "./pages/erroBackup";
import Users from "./pages/users";
import UserLog from "./pages/userLog";
import Queues from "./pages/queue";
import theme from "./theme";
import NotAuth from "./pages/NotAuth";
import { Container, Typography } from "@mui/material";
import { ThemeProvider } from "styled-components";
import Config from "./pages/config";
import Recover from "./pages/recovery/";
import ChangePassword from "./pages/recovery/changePassword";
function Root({children}){
  return(
    <div>
      {children}
    </div>
  )
}
export default function App() {
  return (
    <AuthProvider>
    <Routes>
        <Route>
          <Route path="/" element={
          <RequireAuth>
                <Dashboard />
          </RequireAuth>
              }
              >
             <Route path="/" element={<Main/>}/>
             <Route path="hosts/" element={<Hosts/>} />
             <Route path="backups/" element={<Backups/>} />
             <Route path="erros-backup/" element={<ErroBackups/>} />
             <Route path="usuarios/" element={<Users/>} />
             <Route path="logs/" element={<UserLog/>} />
             <Route path="queues/" element={<Queues/>} />
             <Route path="configs/" element={<Config/>} />
             <Route path="404" element={<NotFound/>} />
             <Route path="401" element={<NotAuth/>} />
             <Route path='*' element={<NotFound />}/>
        </Route>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/recovery" element={<Recover />} />
          <Route path="/change-password" element={<ChangePassword />} />
         </Route>    
      </Routes>
    </AuthProvider>
  );
}


function NotFound() {
  return (
    <Container  maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
    <ThemeProvider theme={theme}>
    <Typography component="h2" variant="h6" color="primary" gutterBottom>
      Página não encontrada.
    </Typography>
    </ThemeProvider>
      </Container>
  )
}