import  { useEffect, useState } from 'react';
import api from '../../services/api';
import Card from '../../componets/card';
import { Paper, Typography } from '@mui/material';
import moment from 'moment';
import 'moment/locale/pt-br'
import { ThemeProvider } from '@mui/material';
import theme from '../../theme';
import { grey } from '@mui/material/colors';
export default function () {
  const [data, setData] = useState(0)
  useEffect(() => {
    api.get("dashboard/time-backup").then((res) => {
      setData(res.data)
    }).catch((err) => {
      console.log(err)
    })
  },[])
    return (
     <ThemeProvider theme={theme}>

      <Paper
      elevation={3}
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: 120,
        justifyContent: 'center', // Centralizar verticalmente
        alignItems: 'center', // Centralizar horizontalmente
        backgroundColor: grey[100]
      }}
    >
      <Typography variant="h4" style={{ fontSize: '29px', textAlign:"center" }}>
      {moment(data).locale('pt-br').format('DD-MM-YYYY HH:mm')}
      </Typography>
      <Typography variant="h6" align='center' style={{ fontSize: '19px', p: 6 }}>
        Horário do Backup em massa
      </Typography>
    </Paper>
    </ThemeProvider>
  );
}