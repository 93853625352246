import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import theme from '../../theme'
import { Alert, Autocomplete, Box, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, MenuItem, Select, Snackbar, Tooltip } from '@mui/material';
import api from '../../services/api';
import { render } from 'react-dom';
import { ConstructionOutlined, Delete } from '@mui/icons-material';
import { useAuth } from '../../auth/AuthProvider';




export default function FormDialog(props) {
  const [handlerSnack, setHandlerSnack] = React.useState()

  const item = {
    username: '',
    name: '',
    role: "",
    email: "",
    password: "",
    active: true,
    


  }
  const [state, setState] = React.useState(item);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCheck = (event) => {

    setState({
      ...state,
      [event.target.name]: event.target.checked
    });
  };

  React.useEffect(() => {
    if (!props.open) return
 
    getEditInfo()

  }, [props.id,  props.open])
 


  const getEditInfo = () => {
    setState(item)
    if (!props.id) return
    api.get(`user/${props.id}`)
      .then(res => {
        console.log(res.data)
        setState(res.data)
      }).catch(err => setState(item))

  }
  const showNotify = (msg, sev = "success") => {
    setMsgSnack(msg)
    setSevSnack(sev)
    setHandlerSnack(true)
  }

  const [msgSnack, setMsgSnack] = React.useState('')
  const [sevSnack, setSevSnack] = React.useState('')
  const [vendors, setVendors] = React.useState([])
  const [types, setTypes] = React.useState([])
  const auth = useAuth()

  

  const handleSubmit = (event) => {
    event.preventDefault()
    const handlerClose = () => {
      props.handlerClose()
    }
    if (!props.id) {
      api.post('user/', state)
        .then((res) => {
          if (res.data.error){
            showNotify(res.data.error, 'error')
            return
          }else{

            showNotify("Usuário adicionado com sucesso.")
            handlerClose()
          }
        }).catch((err) => {
          // treat error 402
          if (err.response.status == 402) {
            showNotify("Você alcançou o limite contratado pela sua licença.", 'error')
            return
          }
          showNotify("Um erro ocorreu...", 'error')
        })
    } else {
      api.put(`user/${props.id}/`, state)
      .then((res) => {
        if (res.data.error){
          showNotify(res.data.error, 'error')
          return
        }else{

          showNotify("Usuário alterado com sucesso.")
          handlerClose()
        }
      }
      ).catch((err) => console.log(err))
    }
  }

  return (
    <div>
      <Snack open={handlerSnack} message={msgSnack} severity={sevSnack} handlerSnack={() => setHandlerSnack(false)} />
      <Dialog open={props.open} fullWidth={true}
        maxWidth='sm'>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <DialogTitle>
          <Grid item xs={12} spacing={1} md={6} sx={{ display: 'flex', justifyContent: "space-between" }}>
              <Grid item xs={6} md={10} >

                  {!props.id ? 'Criar novo usuário' : "Editar usuário"}
              </Grid>
              {props.id ?
          <Grid item xs={6} md={2} >
          <Tooltip title="Remover Usuário">
                  <IconButton  disabled={!auth.hasPerm('user.delete')} sx={{ ml:2 }} onClick={() => {
                    const input = window.confirm('Deseja remover o usuário?')
                    if (!input) return
                    api.delete(`user/${props.id}`).then(res => {
                      props.handlerClose()
                      showNotify('Host apagado com sucesso')
                    }).catch(err => showNotify('Erro ao apagar o usuario', 'error'))
                  }
                  }>
                    <Delete />
                  </IconButton>
                </Tooltip>
          </Grid>
          :""}
            </Grid>
          </DialogTitle>
          <DialogContent>

            <TextField
              required
              size="small"
              margin="dense"
              id="name"
              name="name"
              label="Nome"
              type="text"
              fullWidth
              value={state.name}
              onChange={handleChange}
              variant="outlined"
            />
            <TextField
              required
              size="small"
              margin="dense"
              id="username"
              name="username"
              label="Usuário"
              type="text"
              fullWidth
              value={state.username}
              onChange={handleChange}
              variant="outlined"
            />
            <TextField
              required
              size="small"
              margin="dense"
              id="email"
              name="email"
              label="Email"
              type="text"
              fullWidth
              value={state.email}
              onChange={handleChange}
              variant="outlined"
            />

            <FormControl fullWidth size="small" sx={{
              mt: 1.3
            }}>
              <InputLabel id="role-label">Permissão</InputLabel>
              <Select
                required
                size="small"
                labelId="role"
                id="role"
                name='role'
                value={state.role}
                label="Marca"
                onChange={handleChange}
              >

                
                  <MenuItem key={1} value={1}>Administrador</MenuItem>
                  <MenuItem key={2} value={2}>Edição</MenuItem>
                  <MenuItem key={3} value={3}>Visualização</MenuItem>
                
              </Select>

            </FormControl>
          
            <TextField
              size="small"

              margin="dense"
              id="password"
              name="password"
              label="Senha"
              type="text"
              minlength="8"
              fullWidth
              value={state.password}
              onChange={handleChange}
              variant="outlined"
            />
            
            <FormGroup>
              <FormControlLabel sx={{ ml: 1, mt: 1 }}
                control={
                  <Checkbox checked={state.active} onChange={handleChangeCheck} name="active"
                  />
                }
                label="Ativo"
              />
            </FormGroup>



          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={props.handlerClose}>Cancelar</Button>
            <Button variant='contained' color="primary" type='submit'> {!props.id ? 'Adicionar' : "Editar"}</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}

const Snack = (props) => {
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={6000}
      onClose={() => { props.handlerSnack() }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Alert onClose={() => { props.handlerSnack() }} severity={props.severity ? props.severity : 'error'} sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </Snackbar>
  )
}