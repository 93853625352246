import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import theme from '../../theme';
import { Alert, Box, Checkbox, Container, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, MenuItem, Select, Snackbar, Tooltip, Typography } from '@mui/material';
import api from '../../services/api';
import moment from 'moment';
import { Delete, Download } from '@mui/icons-material';
import fileDownload from 'js-file-download';
import { useAuth } from '../../auth/AuthProvider';

import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import "./main.css"
export default function FormDialog(props) {


  const [handlerSnack, setHandlerSnack] = React.useState()

  const item = {
    id: '',
    host_name: '',
    content: '',
    vendor: '',
    type: '',
    ip_address: '',
    host_id: '',
    created_at: '',


  }
  const [state, setState] = React.useState(item);
  const [backups, setBackups] = React.useState([]);
  const auth = useAuth()



  React.useEffect(() => {
    if (!props.open) return
    getEditInfo(props.id)


  }, [props.id, handlerSnack, props.open])
  React.useEffect(() => {

    getBackups()
  }, [state.host_id])

  const getBackups = () => {
    if (state?.host_id) {

      api.get(`backup/host/${state.host_id}`).then(res => {
        setBackups(res.data.results)
      }).catch(err => console.log(err))
    }
  }
  const getEditInfo = (id) => {
    
    setState(item)
    if (!props.id) return
    api.get(`backup/${id}`)
      .then(res => {
        console.log(res.data)
        setState(res.data.result)


      }).catch(err => setState(item))
  }
  const showNotify = (msg, sev = "success") => {
    setMsgSnack(msg)
    setSevSnack(sev)
    setHandlerSnack(true)
  }

  const [msgSnack, setMsgSnack] = React.useState('')
  const [sevSnack, setSevSnack] = React.useState('')


  return (
    <div>
      <Snack open={handlerSnack} message={msgSnack} severity={sevSnack} handlerSnack={() => setHandlerSnack(false)} />
      <Dialog open={props.open} fullWidth={true} scroll={'paper'}
        maxWidth='lg'>

        <DialogTitle>
          <Grid container spacing={1}>
            <Grid item xs={12} md={8} >
              <Typography variant='h6' color='textPrimary' sx={{ mt: 1 }}>
                {state.host_name} - {state.ip_address} - {state.vendor} - {state.type}
              </Typography>
            </Grid>
            <Grid item xs={12} spacing={1} md={4} sx={{ display: 'flex', justifyContent: "flex-end", alignItems: 'flex-end' }}>
              <Grid item xs={6} md={10}>
                <FormControl fullWidth size='small' >
                  <InputLabel id="timestamp-label">Versão:</InputLabel>
                  <Select
                    labelId="timestamp-label"
                    size="small"
                    id="backup"
                    label="Realizado em:"
                    name='backup'
                    value={state?.id || props.id}
                    onChange={(e) => {
                      getEditInfo(e.target.value)
                     
                    }}
                  >

                    {backups ? backups.map((backup, i) =>
                      <MenuItem key={backup.id} value={backup.id}>{moment(backup.created_at).format('DD-MM-YYYY HH:mm:ss')} {moment(backup.created_at).locale('pt-br').fromNow()}</MenuItem>
                    ) : ''}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Baixar">
                  <IconButton  disabled={!auth.hasPerm('backup.get')} sx={{ ml:2 }} onClick={() => {
                    api.get(`backup/download/${state.id}`).then(res => {
                      //Downlaod txt file
                      fileDownload(res.data, `${state.host_name} - ${state.ip_address} - ${moment(state.created_at).format('DD-MM-YYYY HH:mm:ss')}.txt`);
                    })
                  }}>
                    <Download />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Apagar backup">
                  <IconButton  disabled={!auth.hasPerm('backup.delete')} sx={{ ml:2 }} onClick={() => {
                    const input = window.confirm('Deseja apagar o backup?')
                    if (!input) return
                    api.delete(`backup/${state.id}`).then(res => {
                      props.handlerClose()
                      showNotify('Backup apagado com sucesso')
                    }).catch(err => showNotify('Erro ao apagar backup', 'error'))
                  }
                  }>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent
        
        >
        



         <CodeMirror value={state.content} 
         editable={false}
         extensions={[javascript({ jsx: true })]} 
         options={{
           mode: 'javascript',
           theme: 'material',

           lineNumbers: true,
           viewportMargin: 50
          }}
          style={{
            height: '100%',
            width: 'auto',
          }}
          
          />



        </DialogContent>
        <DialogActions>
          <Button sx={{  }} onClick={props.handlerClose}>Fechar</Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

const Snack = (props) => {
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={6000}
      onClose={() => { props.handlerSnack() }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Alert onClose={() => { props.handlerSnack() }} severity={props.severity ? props.severity : 'error'} sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </Snackbar>
  )
}