import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import theme from '../../themeLayout'
import { Alert, Autocomplete, Box, Checkbox, FormControl, FormControlLabel, FormGroup, Input, InputLabel, MenuItem, Select, Snackbar, Typography } from '@mui/material';
import api from '../../services/api';
import env from "react-dotenv";



export default function FormDialog(props) {
  const [handlerSnack, setHandlerSnack] = React.useState()
  const [ filename, setFilename ] = React.useState('Escolha seu arquivo.')
  const [ file, setFile ] = React.useState("")
  const item = {
    host_name: '',
    ip_address: '',
    type: "",
    vendor: "",
    port: '',
    enable_password: '',
    password: '',
    username: '',
    status: true,
    protocol: "",


  }
  const [state, setState] = React.useState(item);



  React.useEffect(() => {
   if (!props.open) return
    setFilename('Escolha seu arquivo.')

  }, [props.open])



 
  const showNotify = (msg, sev = "success") => {
    setMsgSnack(msg)
    setSevSnack(sev)
    setHandlerSnack(true)
  }

  const [msgSnack, setMsgSnack] = React.useState('')
  const [sevSnack, setSevSnack] = React.useState('')
  const [vendors, setVendors] = React.useState([])
  const [types, setTypes] = React.useState([])



  const handleSubmit = (event) => {
    const formData = new FormData()
    formData.append('file', file)
    const handlerClose = () => {
      props.handlerClose()
    }
    event.preventDefault()
    
      api.post('host/bulk', formData)
        .then((res) => {
          if(res.data.error){
            showNotify(res.data.error, 'error')
          }else{
          showNotify("Registros adicionados com sucesso.")
          handlerClose()
          }
        }).catch((err) => {
          console.log(err)
          showNotify("Um erro ocorreu...", 'error')
        })
   
  }
  const styles = {
    hidden: {
      display: "none",
    },
    importLabel: {
      backgroundColor: "#1976D2", // Cor de fundo típica de um botão MUI
    color: "#FFF", // Cor do texto típica de um botão MUI
    borderRadius: "4px", // Borda arredondada típica de um botão MUI
    padding: "8px 16px", // Preenchimento típico de um botão MUI
    cursor: "pointer", // Cursor de apontador ao passar o mouse
    textDecoration: "none", // Remova sublinhado de texto
    display: "inline-block", // Exibe como um elemento em linha
    textAlign: "center", // Alinhamento do texto
    fontWeight: "bold", // Peso da fonte em negrito
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Sombra típica de um botão MUI
    transition: "background-color 0.3s ease", // Transição suave ao passar o mouse
    },
    importLabelHover: {
      backgroundColor: "#1565C0", // Cor de fundo quando o mouse está sobre o botão
    },
  };
  return (
    <div>
      <Snack open={handlerSnack} message={msgSnack} severity={sevSnack} handlerSnack={() => setHandlerSnack(false)} />
      <Dialog open={props.open} fullWidth={true}
        maxWidth='sm'>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <DialogTitle>
            Criar hosts a patir de um CSV ou EXCEL
          </DialogTitle>
          <DialogContent>

          <InputLabel htmlFor="import-button" style={styles.importLabel}>
    <Input
        required
        id="import-button"
        inputProps={{
          accept: ".csv, .xlsx, .xls"
        }}
        onChange={e=>{ 
          setFilename(e.target.files[0].name)
          setFile(e.target.files[0])
        }}
        style={styles.hidden}
        type="file"
    />
        {filename}
</InputLabel>
<Typography variant="h6">Padrões para Inserir um Arquivo:</Typography>
        <Typography variant="body1">
          Ao enviar um arquivo para nossa plataforma, é fundamental seguir alguns padrões específicos para garantir uma integração eficaz e sem problemas. Aqui estão os critérios que você deve cumprir:
        </Typography>
        <ol>
          <li>
            <Typography>
              <strong>Formato do Arquivo:</strong> Seu arquivo deve estar em um dos seguintes formatos: CSV (Comma Separated Values), XLS (Microsoft Excel) ou XLSX (Microsoft Excel). Esses são os formatos de arquivo suportados para importação de dados.
            </Typography>
          </li>
          <li>
            <Typography>
              <strong>Tamanho Máximo:</strong> O arquivo que você deseja enviar não deve exceder o tamanho máximo de 20 megabytes (MB). Certifique-se de que seu arquivo se encaixa nesse limite para evitar quaisquer problemas durante o processo de envio.
            </Typography>
          </li>
          <li>
            <Typography>
              <strong>Padrão das Colunas:</strong> Marca, Tipo, Nome, IP do Usuário, Senha, Enable, Protocolo, Porta. Seu arquivo deve seguir o padrão específico das colunas, conforme exemplificado.
            </Typography>
          </li>
        </ol>
        <Typography variant="body1">
          Certifique-se de que seu arquivo contenha essas colunas exatamente como indicado no exemplo, com os mesmos nomes de colunas, e com os dados correspondentes. Isso é essencial para garantir que os dados sejam importados e processados corretamente.
        </Typography>
        <Box display={"flex"} sx={{justifyContent: "center"}}>
        <Button 
        onClick={()=>
          window.location.href =  api.getUri() +  '/host/download/template'
        }
        sx={{textAlign: "center"}}>Baixar modelo de Template</Button>
        </Box>
        


          </DialogContent>
          <DialogActions>
            <Button variant='outlined'  onClick={props.handlerClose}>Cancelar</Button>
            <Button variant='contained' color="primary" type='submit'> Adicionar</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}

const Snack = (props) => {
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={6000}
      onClose={() => { props.handlerSnack() }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Alert onClose={() => { props.handlerSnack() }} severity={props.severity ? props.severity : 'error'} sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </Snackbar>
  )
}