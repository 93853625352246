import React, { useEffect } from 'react';
import { useLocation, useNavigate, Navigate, redirect } from 'react-router-dom';

import api from '../services/api';
import erroBackup from '../pages/erroBackup';

let AuthContext = React.createContext(null);

export function AuthProvider({ children }) {
  const [user, setUser] = React.useState();
  const [perms, setPerm] = React.useState([]);
  const [name, setName] = React.useState();
  const [logout, setLogout] = React.useState(false); // Variável de controle para forçar a validação do token após o logout
  const navigate = useNavigate();
 
  
  React.useEffect(() => {
    if (validateToken()) {
      getUser();
    }else{
      //navigate('/login')
    }
  }, []); 


  function getToken() {
    const cookieValue = document.cookie
      .split(';')
      .find((row) => row.startsWith('token='))
      ?.split('=')[1];
    return cookieValue;
  }

  function setToken(tk) {
    let now = new Date();
    const time = now.getTime();
    const expireTime = time + 1000 * 60 * 30 * 48;
    now.setTime(expireTime);
    document.cookie = `token=${tk}; expires=${now.toUTCString()}; SameSite=Strict `;
    api.defaults.headers.common['Authorization'] = tk;
  }

  function deleteCookies() {
    var allCookies = document.cookie.split(';');

    // The "expire" attribute of every cookie is
    // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
    for (var i = 0; i < allCookies.length; i++)
      document.cookie = allCookies[i] + '=;expires=' + new Date(0).toUTCString();
  }

  function validateToken() {
    if (getToken() == undefined || getToken() == "") {
      setUser(null);
      return false;

    }
    setToken(getToken());
    return true;
  }
  function getUser(){
    api
      .get('/login/me/')
      .then((res) => {
        setUser(res.data.username);
        setName(res.data.name);
        setPerm(res.data.permissions);
      })
      .catch((err) => {
        deleteCookies();
        if (err.code==400 || err.code==401){
          navigate('/login')
        }
       
      });
  }

  const checkPerm = (p) => perms.filter((perm) => perm.description === p);
  const hasPerm = (p) => checkPerm(p).length > 0;

  const signin = (cred, callback, clerr) => {
    api
      .post('/login/token/', cred)
      .then((res) => {
        setUser(res.data.username);
        const token = `Bearer ${res.data.access_token}`;
        setToken(token);
        getUser();
        callback();
        return true;
      })
      .catch((err) => {
        clerr();
        return false;
      });



  };

  const signout = (callback = () => {}) => {
    setUser(null);
    deleteCookies();
    navigate('/login')
    callback();
  };

  let value = { user,name, validateToken, signin, signout, hasPerm, getToken };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();
  if (auth.user === undefined) {
    return null; // or loading indicator, etc...
  }

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function AuthStatus() {
  let auth = useAuth();
  let navigate = useNavigate();

  if (!auth.user) {
    return <p>You are not logged in.</p>;
  }

  return (
    <p>
      Welcome {auth.user}!{' '}
      <button
        onClick={() => {
          auth.signout(() => navigate('/'));
        }}
      >
        Sign out
      </button>
    </p>
  );
}
