import { ThemeProvider } from "@mui/material";
import { Paper, Typography } from "@mui/material";
import theme from "../themeLayout";

export default function({title, subtitle}){
    return(
      <ThemeProvider theme={theme}>

        <Paper
          elevation={3}

        sx={{
          p: 2, 
          display: 'flex',
          flexDirection: 'column',
          height: 120,
          
          justifyContent: 'center', // Centralizar verticalmente
          alignItems: 'center', // Centralizar horizontalmente
        }}
      >
        <Typography variant="h4" style={{ fontSize: '46px' }}>
          {title}
        </Typography>
        <Typography variant="h6" align="center" style={{ fontSize: '24px', p: 6 }}>
          {subtitle}
        </Typography>
      </Paper>
      </ThemeProvider>
    )
}