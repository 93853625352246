import React from "react";
import axios from "axios";
import env from "react-dotenv";


const api = axios.create({
    baseURL:process.env.REACT_APP_API_URL,
   
  
});


  export default api