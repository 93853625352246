import { ThemeProvider } from '@emotion/react'
import { Box, Button, Container, Grid, IconButton } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import * as React from 'react'
import theme from '../../themeLayout'
import api from '../../services/api'
import AddIcon from '@mui/icons-material/Add';
import FormDialog from './FormDialog'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../auth/AuthProvider'
import { useNavigate } from 'react-router-dom'
import textLabels from '../../componets/textLabelsTable';
import moment from 'moment';
import 'moment/locale/pt-br'

export default function(){
    const module = "/user"  
    const navigate = useNavigate()
    const [ handlerDialog, setHandlerDialog ] = React.useState(false)
    const [update, setUpdate] = React.useState(false)
    const [id, setId] = React.useState()
    const  auth = useAuth()
    React.useEffect(()=>{
        document.title = "Usuários - Hermes Backup"
      },[])

      React.useEffect(()=>{    
        updateData()
    },[])
    const [data, setData] = React.useState({results:[], limit:10, total:0})
    const columns =[
        {
            name:'id',
            label: "ID",
            options: {
                filter: false,
                sort:false,
                display: false
            }
        },
        {
            name:'name',
            label: "Nome",
            options: {
                filter: true,
                sort:false,}
        },
        {
            name: "username",
            label:'Nome do Usuário',
            options: {
                filter: true,
                sort:false,}
        },
        {
            name: "role",
            label:'Permissão',
            options: {
                filter: true,
                sort:false,}
        },
        {
            name: "email",
            label:'Email',
            options: {
                filter: true,
                sort:false,}
        },
        {
            name: "created_at",
            label:'Data de Criação',
            options: {
                filter: true,
                sort:false,
                customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
              }
        },
        {
            name: "updated_at",
            label:'Última de Atualização',
            options: {
                filter: true,
                sort:false,
                customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
              }
        },
        {
            name: "active",
            label:'Status',
            options: {
                customBodyRender: value => <span>{value ? "Ativo" : 'Inativo'}</span>,
                filter: true,
                sort:false,}
        },
        
        {
            name: "actions",
            label: "Ações",
            options:{
              sort:false,
              customBodyRenderLite : (dataIndex, rowIndex)=>
        
                <Grid container spacing={0} sx={{
                  "display": "flex",
                }}>
                
                <Grid item xs={12} sm={6}>
                    <Button  disabled={!auth.hasPerm('user.update')}  sx={{ color:theme.palette.text.primary}}   onClick={()=>{
                      const id = data.results[rowIndex].id
                    
                        setId(id)
                        setHandlerDialog(true)
                    }}>
                    <EditIcon/>
                </Button>
                </Grid>
            </Grid>
            
            }
          }
    ]
   
    const updateData = (page = 0, limit = 10, search=false) => {
        const skip = page * limit;
      
        const url = search  ? `${module}/?skip=${skip}&limit=${limit}&search=${search}` : `${module}/?skip=${skip}&limit=${limit}`;
      
        api.get(url)
          .then(res => {
            
            setData(res.data);
          })
          .catch(err => {
            if (err.response && err.response.status === 401) {
              navigate('/401');
            }
            setData();
          });
      };
    const showFormDialog = ()=>{
        setId()
        setHandlerDialog(true)
    }






    const options = {
        filterType: 'checkbox',
        filter: false,
        rowsPerPageOptions : [ 10, 30,100, 500],
        rowsPerPage: 10,
        textLabels,
        rowHover: false,
        print: false,
        rowsPerPage: data?.limit,
        count: data?.total,
        selectableRows: 'none' ,
        download: false,
        customToolbar : ()=> <IconButton  disabled={!auth.hasPerm('user.create')} onClick={showFormDialog} sx={{color: theme.palette.text.primary }}><AddIcon/></IconButton>, 
        customToolbarSelect: () => {},
        serverSide: true,
        storageKey: 'hosts',
        onTableChange: (action, tableState) => {
            const {page,rowsPerPage, searchText } = tableState
            switch (action) {
              case 'changePage':
                updateData(page)
                break;
              case 'changeRowsPerPage':
                updateData(page, rowsPerPage)
                break;
              case 'search':
                console.log(searchText)
                updateData(page, rowsPerPage, searchText)
                break;
              case "propsUpdate":
                if (update){
                  setUpdate(false)
                  updateData(page, rowsPerPage, searchText)
                }
                break;
              default:
               //console.log(action, tableState);
            }
          },
    }
    return(
        <Container  maxWidth='lg' sx={{ mt: 1, mb: 1 }}>
            <ThemeProvider theme={theme}>
            <FormDialog open={handlerDialog} id={id} handlerClose={()=>{
              setHandlerDialog(false) 
              setUpdate(true)}}/>
            <Box sm={12} lg={10}>
            <MUIDataTable
            title={"Usuários"}
            data={data?.results}
            columns={columns}
            options={options}
            />
            </Box>
        </ThemeProvider>
          </Container>
    )
}