import { grey, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';



const theme = createTheme({
  typography:{
    fontFamily:[
      'sans-serif',
      'Roboto',
      'Montserrat',
    ]
  },

  MUIDataTableHeadCell: {
    toolButton: {
      justifyContent: 'center'
    },
  },
  
  palette: {
    mode: 'light',
    primary: {
      main: "#e01b29",
    },
    secondary: {
      main: '#7c1d8c',
    },
    warning: {
      main: '#ed6c02',
    },
    info: {
      main: '#0288d1',
    },
    text: {
      //primary: '#fdfdfd',
      //secondary: '#fdfdfd',
      disabled: '#dadada',
      hint: '#245fd6',
    },
    error: {
      main: '#aa2525',
    },
    success: {
      main: '#2e7d32',
    },
    background: {
      paper: '#2B2E35',
    },
  },
  
});

export default theme;
