import { ThemeProvider } from '@emotion/react'
import { Box, Button, Container, IconButton } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import * as React from 'react'
import theme from '../../themeLayout'
import api from '../../services/api'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../auth/AuthProvider'
import { useNavigate } from 'react-router-dom'
import textLabels from '../../componets/textLabelsTable';
import moment from 'moment';
import 'moment/locale/pt-br'

export default function(){
    const module = "/audit"  
    const navigate = useNavigate()
    const [ handlerDialog, setHandlerDialog ] = React.useState(false)
    const [update, setUpdate] = React.useState(false)
    const [id, setId] = React.useState()
    const  auth = useAuth()
    React.useEffect(()=>{
        document.title = "Hosts - Hermes Backup"
      },[])

    React.useEffect(()=>{
        updateData()

    },[])
    const [data, setData] = React.useState({results:[], limit:10, total:0})
    const columns =[
        {
            name:'id',
            label: "ID",
            options: {
                filter: false,
                sort:false,
                display: false
            }
        },

        {
            name: "message",
            label:'Mensagem',
            options: {
                filter: true,
                sort:false,}
        },
        {
          name: "username",
          label:'Usuário',
          options: {
              filter: true,
              sort:false,}
        },
        {
          name: "created_at",
          label:'Data de Criação',
          options: {
              filter: true,
              sort:false,
              customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
            }
      },
        
        

    ]
   
    const updateData = (page = 0, limit = 10, search=false) => {
        const skip = page * limit;
        const url = search  ? `${module}/?skip=${skip}&limit=${limit}&search=${search}` : `${module}/?skip=${skip}&limit=${limit}`;
      
        api.get(url)
          .then(res => {
            
            setData(res.data);
 
          })
          .catch(err => {
            if (err.response && err.response.status === 401) {
              navigate('/401');
            }
            setData();
          });
      };
    const showFormDialog = ()=>{
        setId()
        setHandlerDialog(true)
    }






    const options = {
        filterType: 'checkbox',
        rowsPerPageOptions : [ 10, 30,100, 500],
        rowsPerPage: 10,
        textLabels,
        filter: false,
        rowHover: false,
        print: false,
        rowsPerPage: data?.limit,
        count: data?.total,
        selectableRows: 'none' ,
        download: false,
        customToolbarSelect: () => {},
        serverSide: true,
        storageKey: 'hosts',
        onTableChange: (action, tableState) => {
            const {page,rowsPerPage, searchText } = tableState
            switch (action) {
              case 'changePage':
                updateData(page)
                break;
              case 'changeRowsPerPage':
                updateData(page, rowsPerPage)
                break;
              case 'search':
                updateData(page, rowsPerPage, searchText)
                break;
              case "propsUpdate":
                if (update) {
                    setUpdate(false)
                    updateData(page, rowsPerPage, searchText)
                  }
                break;
              
              default:
               //console.log(action, tableState);
            }
          },
    }
    return(
        <Container  maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
            <ThemeProvider theme={theme}>
            <Box sm={12} lg={10}>
            <MUIDataTable
            title={"Logs"}
            data={data?.results}
            columns={columns}
            options={options}
            />
            </Box>
        </ThemeProvider>
          </Container>
    )
}