import  { useEffect, useState } from 'react';
import api from '../../services/api';
import Card from '../../componets/card';


export default function () {
  const [data, setData] = useState(0)
  useEffect(() => {
    api.get("dashboard/active-hosts").then((res) => {
        setData(res.data)
},[]
)})
    return (
    <Card title={data} subtitle="Hosts ativos"/>
  );
}