import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Description, Lan, MiscellaneousServices, Subscriptions } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import Config from '../../pages/config';
import Profile from '../../pages/profile';
import theme from '../../themeLayout';
import { List, ThemeProvider } from '@mui/material';

const style = { color: grey[100]}
export const MainListItems =()=> (

  <List>
    <Link to={"/"} style={{ textDecoration: 'none', color: grey[100]}}>
    <ListItemButton>
      <ListItemIcon>
        <DashboardIcon sx={style}/>
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    </Link>

    <Link to={"/hosts"} style={{ textDecoration: 'none', color: grey[100]}}>
    <ListItemButton>
      <ListItemIcon>
        <Lan sx={style}/>
      </ListItemIcon>
      <ListItemText primary="Hosts" />
    </ListItemButton>
    </Link>

    <Link to={"/backups"} style={{ textDecoration: 'none', color: grey[100]}}>
    <ListItemButton>
      <ListItemIcon>
        <Description sx={style}/>
      </ListItemIcon>
      <ListItemText primary="Backups" />
    </ListItemButton>
    </Link>

    <Link to={"/erros-backup"} style={{ textDecoration: 'none', color: grey[100]}}>
    <ListItemButton>
      <ListItemIcon>
        <BarChartIcon sx={style}/>
      </ListItemIcon>
      <ListItemText primary="Relatório de Erros" />
    </ListItemButton>
    </Link>

    <Link to={"/queues"} style={{ textDecoration: 'none', color: grey[100]}}>
    <ListItemButton>
      <ListItemIcon>
        <Subscriptions sx={style}/>
      </ListItemIcon>
      <ListItemText primary="Fila" />
    </ListItemButton>
    </Link>

    <Link to={"/usuarios"} style={{ textDecoration: 'none', color: grey[100]}}>
    <ListItemButton>
      <ListItemIcon>
        <PeopleIcon sx={style}/>
      </ListItemIcon>
      <ListItemText primary="Usuários" />
    </ListItemButton>
    </Link>
    <Profile/>
    <Config/>
    <Link to={"/logs"} style={{ textDecoration: 'none', color: grey[100]}}>
      <ListItemButton>
        <ListItemIcon>
          <BarChartIcon sx={style}/>
        </ListItemIcon>
        <ListItemText primary="Logs dos usuários" />
      </ListItemButton>
    </Link>
    
  </List>
);
