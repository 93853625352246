import { ThemeProvider } from '@emotion/react'
import { Box, Button, Container, IconButton, InputLabel, MenuItem, Select } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import * as React from 'react'
import theme from '../../themeLayout'
import api from '../../services/api'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../auth/AuthProvider'
import { useNavigate } from 'react-router-dom'
import textLabels from '../../componets/textLabelsTable';
import { FormControl } from '@mui/material'
import moment from 'moment';
import 'moment/locale/pt-br'

export default function () {
  const module = "/queue"
  const navigate = useNavigate()
  const [handlerDialog, setHandlerDialog] = React.useState(false)
  const [update, setUpdate] = React.useState(false)
  const [id, setId] = React.useState()
  const [queueStatus, setQueueStatus] = React.useState(1)
  const auth = useAuth()
  React.useEffect(() => {
    document.title = "Fila de Backups - Hermes Backup"
  }, [])

  React.useEffect(() => {
    setUpdate(false)
    updateData()

  }, [handlerDialog, update, queueStatus])
  const [data, setData] = React.useState({ results: [], limit: 10, total: 0 })
  const columns = [
    {
      name: 'id',
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: 'host_name',
      label: "Host",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "ip_address",
      label: 'IP Address',
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "vendor",
      label: 'Marca',
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "type",
      label: 'Tipo',
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "created_at",
      label: 'Executado em',
      options: {
        customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
        filter: true,
        sort: false,
      }
    },




  ]

  const updateData = (page = 0, limit = 30, search = false) => {
    const skip = page * limit;

    const url = search ? `${module}/?skip=${skip}&limit=${limit}&search=${search}&status=${queueStatus}` : `${module}/?skip=${skip}&limit=${limit}&status=${queueStatus}`;

    api.get(url)
      .then(res => {
        setData(res.data);

      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          navigate('/401');
        }
        setData();
      });
  };
  const showFormDialog = () => {
    setId()
    setHandlerDialog(true)
  }






  const options = {
    filterType: 'checkbox',
    filter: false,
    rowsPerPageOptions: [10, 30, 100, 500],
    rowsPerPage: 10,
    textLabels,
    rowHover: false,
    print: false,
    rowsPerPage: data?.limit,
    count: data?.total,
    selectableRows: 'none',
    download: false,
    customToolbar: () => {
      return (

        <FormControl size="small"  >
          <InputLabel id="role-label">Status</InputLabel>
          <Select
            required
            size="small"
            labelId="queue"
            id="que"
            name='que'
            value={queueStatus}
            label="Marca"
            onChange={(e) => setQueueStatus(e.target.value)}
          >
            <MenuItem key={1} value={1}>Ativos</MenuItem>
            <MenuItem key={0} value={0}>Finalizados</MenuItem>


          </Select>

        </FormControl>
      )


    },
    serverSide: true,
    storageKey: 'queue',
    onTableChange: (action, tableState) => {
      const { page, rowsPerPage, searchText } = tableState
      switch (action) {
        case 'changePage':
          updateData(page)
          break;
        case 'changeRowsPerPage':
          updateData(page, rowsPerPage)
          break;
        case 'search':
          updateData(page, rowsPerPage, searchText)
          break;

        default:
        //console.log(action, tableState);
      }
    },
  }
  return (
    <div style={{ display: 'table', tableLayout: 'fixed', width: '100%' }} >
      <Container maxWidth={"lg"}>

        <ThemeProvider theme={theme}>
          <Box sm={12} lg={12}>
            <MUIDataTable
              title={
                "Fila de Execução dos Backups"

              }
              data={data?.results}
              columns={columns}
              options={options}
            />
          </Box>
        </ThemeProvider>
      </Container>
    </div>
  )
}