import { Container, Grid, ListItemButton, ListItemIcon, ListItemText, Paper, ThemeProvider, Typography } from '@mui/material'
import React from 'react'
import theme from '../../themeLayout'
import { MiscellaneousServices } from '@mui/icons-material'
import FormDialog from './FormDialog'
import { grey } from '@mui/material/colors'

export default function (){
    const [open, setOpen] = React.useState(false);
    return(

     <>
        <FormDialog open={open} handlerClose={()=>setOpen(false)}/>
      <ListItemButton
      onClick={()=>setOpen(true)}
      >
      <ListItemIcon>
        <MiscellaneousServices sx={{ color: grey[100]}}/>
      </ListItemIcon>
      <ListItemText primary="Configurações" sx={{color:grey[100]}} />
    </ListItemButton>
     </>


    )
}