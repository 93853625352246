export default  {
    body: {
      noMatch: "Desculpe, nenhum registro correspondente encontrado",
      toolTip: "Ordenar",
      columnHeaderTooltip: coluna => `Ordenar por ${coluna.label}`
    },
    pagination: {
      next: "Próxima Página",
      previous: "Página Anterior",
      rowsPerPage: "Linhas por página:",
      displayRows: "de",
    },
    toolbar: {
      search: "Pesquisar",
      downloadCsv: "Download CSV",
      print: "Imprimir",
      viewColumns: "Ver Colunas",
      filterTable: "Filtrar Tabela",
    },
    filter: {
      all: "Tudo",
      title: "FILTROS",
      reset: "RESETAR",
    },
    viewColumns: {
      title: "Mostrar Colunas",
      titleAria: "Mostrar/Ocultar Colunas da Tabela",
    },
    selectedRows: {
      text: "linha(s) selecionada(s)",
      delete: "Excluir",
      deleteAria: "Excluir Linhas Selecionadas",
    },
  }
  